import { useAuth } from "@with-nx/auth";
import { useShoppingCart } from "@with-nx/ecommerce";
import { useCMSAnnouncements, useCustomMobile, useMobile } from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import {
  DesignedButton,
  DesignedIcon,
  DesignedTag,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { useOuterClick } from "@with-nx/simple-ui/molecules";
import { DropdownMenu, GlobalSearchInput } from "@with-nx/simple-ui/organisms";
import { useRouter } from "next/router";
import React, { useRef, useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Box } from "simple-effing-primitive-layout";

import styles from "./navbar.module.css";
import MenuSidebar from "./sidebar";

/* eslint-disable-next-line */
export interface NavbarProps {
  authenticated?: boolean;
  isOpen?: boolean;
  hideNavbar?: boolean;
  hideSearchBar?: boolean;
  transparentFixedNavBar?: boolean;
  hideAuthButtons?: boolean;
  shoppingCartQuantity?: number;
  hideProfileActions?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({
  isOpen = false,
  hideNavbar = false,
  hideSearchBar = false,
  transparentFixedNavBar,
  hideAuthButtons = false,
  hideProfileActions,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const { methods, quantity } = useShoppingCart();

  const router = useRouter();

  const searchRef = useRef<HTMLInputElement>(null);

  const { authState } = useAuth();

  const authenticated = authState.status === "authenticated";

  const mobile = useCustomMobile(1200);
  const region = useServerRegion();
  const announcements = useCMSAnnouncements(
    region?.id
      ? {
          filter: {
            regionTags: [region.id.toString()],
          },
        }
      : {},
    Boolean(region?.id)
  );

  const wrapper = useRef<HTMLDivElement>(null);
  useOuterClick(wrapper, () => setIsMobileMenuOpen(false));

  if (hideNavbar) {
    return null;
  }

  const handleGoToCheckout = () => {
    methods.step.set(0);
    router.push("/checkout");
  };

  return (
    <>
      {announcements.loading ? null : announcements?.random && !mobile ? (
        <Box
          parse="d:flex a:center j:center h:48"
          color={announcements.random.background}
          element="a"
          native={{
            href: announcements.random.url || "/",
          }}
        >
          {announcements.random.badge ? (
            <DesignedTag
              size="small"
              color="black"
              background="white"
              properties={{
                style: {
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                },
                right: 12,
              }}
              label={announcements.random.badge}
            />
          ) : undefined}
          <Rule parse="!lt" color={announcements.random.color}>
            {announcements.random.title}
          </Rule>
        </Box>
      ) : undefined}
      <nav
        className="bg-transparent"
        style={
          transparentFixedNavBar
            ? {
                position: "absolute",
                top: announcements?.random && !mobile ? 48 : 0,
                left: 0,
                zIndex: 6,
                width: "100%",
              }
            : {}
        }
      >
        <div className="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-[96px]">
            <a href="/" rel="nofollow">
              <img
                src="/assets/logo/logo-broadway-media-primary.svg"
                alt="Broadway Media Logo"
                className="navbar-logo-image"
              />
            </a>
            {mobile ? (
              <Box css="d:inline-flex a:center">
                {authenticated && !hideProfileActions ? (
                  <div className="flex items-center space-x-4">
                    <Box css="d:inline-flex a:center">
                      {quantity > 0 ? (
                        <>
                          <DesignedIcon
                            name="cart/regular"
                            color={transparentFixedNavBar ? "white" : "#e2b576"}
                            size={24}
                            press={handleGoToCheckout}
                            properties={{
                              id: "icon-checkout",
                              cypress: "icon-checkout",
                            }}
                          />
                          <div style={{ color: "white" }}>{quantity}</div>
                        </>
                      ) : undefined}
                      <Box css="d:inline-flex a:center j:center" parse="ml:10">
                        <DesignedIcon
                          name="user/regular"
                          color={transparentFixedNavBar ? "white" : "#e2b576"}
                          size={24}
                          press={() => setIsProfileMenuOpen(true)}
                        />
                      </Box>
                    </Box>
                  </div>
                ) : (
                  <div className="flex items-center space-x-4">
                    {hideAuthButtons ? undefined : (
                      <>
                        <a
                          className={`nav-link !w-auto ${
                            transparentFixedNavBar
                              ? "text-white"
                              : "text-brand-primary"
                          } text-label-md`}
                          href="/auth"
                          rel="nofollow"
                        >
                          Sign In
                        </a>
                        <DesignedButton
                          label="Get Started"
                          size="small"
                          theme={transparentFixedNavBar ? "white" : "primary"}
                          href={`/contact/start?mode=get-started&referrer=${router.asPath}`}
                        />
                      </>
                    )}
                  </div>
                )}
                <Box parse="ml:24" press={() => setIsMobileMenuOpen(true)}>
                  {isMobileMenuOpen ? (
                    <FiX size={24} color="var(--primary)" title="x icon" />
                  ) : (
                    <FiMenu
                      size={24}
                      color={
                        transparentFixedNavBar ? "var(--font3)" : "var(--font4)"
                      }
                      title="menu icon"
                    />
                  )}
                </Box>
                {isMobileMenuOpen ? (
                  <Box
                    parse="p:fixed i:99 t:0 l:0 r:0 b:0 br:5 pt:75 pl:15 pr:15 pb:15 d:flex fd:column"
                    color="rgba(0,0,0,0.5)"
                    style={{
                      backdropFilter: "blur(5px)",
                    }}
                  >
                    <Box
                      parse="ml:24 p:fixed i:100 t:35 r:15"
                      press={() => setIsMobileMenuOpen(false)}
                    >
                      <FiX size={24} color="var(--primary)" title="x icon" />
                    </Box>
                    <Box
                      reference={wrapper}
                      parse="c:?foreground br:10 pa:20 d:flex fd:column f:1 oy:auto pb:100"
                      css="--dark-mode-scrollbar"
                    >
                      <Box>
                        <GlobalSearchInput
                          reference={searchRef}
                          size="medium"
                          properties={{
                            bottom: 24,
                          }}
                        />
                      </Box>
                      <DropdownMenu
                        image="/assets/section/menu-products.webp"
                        label="Products"
                        description=" All the tools you'll need to take your production to the next level."
                        options={[
                          {
                            label: "Digital Scenery",
                            options: [
                              {
                                label: "Show Packages",
                                url: "/scenic-projections",
                              },
                              {
                                label: "Backdrops Library",
                                url: "/digital-backdrops",
                              },
                              {
                                label: "View All",
                                url: "/search",
                              },
                            ],
                          },
                          {
                            label: "Guides",
                            options: [
                              {
                                label: "Choreography Guides",
                                url: "/choreography-guides",
                              },
                            ],
                          },
                          region?.id === 1
                            ? {
                                label: "Stage Gear",
                                options: [
                                  {
                                    label: "Projector Rentals",
                                    url: "/stage-gear/projectors",
                                  },
                                  {
                                    label: "Wireless Microphones",
                                    url: "/stage-gear/wirelesssystems",
                                  },
                                  {
                                    label: "View All",
                                    url: "/stage-gear",
                                  },
                                ],
                              }
                            : {},
                        ]}
                        mobile
                      />
                      <Box parse="h:24" />
                      <DropdownMenu
                        image="/assets/section/menu-company.webp"
                        label="Company"
                        description="Learn more about Broadway Media"
                        options={[
                          {
                            label: undefined,
                            options: [
                              {
                                label: "About Us",
                                url: "/company",
                              },
                              {
                                label: "News",
                                url: "/blog?filter=Press+Release",
                              },
                              {
                                label: "Partners",
                                url: "/partners",
                              },
                              {
                                label: "Clients",
                                url: "/clients",
                              },
                              {
                                label: "Contact Us",
                                url: "/contact",
                              },
                            ],
                          },
                        ]}
                        mobile
                      />
                      <Box parse="h:24" />
                      <DropdownMenu
                        image="/assets/section/menu-resources.webp"
                        label="Resources"
                        description="Join theatre-makers around the globe celebrating theatre, dance and music"
                        options={[
                          {
                            label: undefined,
                            options: [
                              {
                                label: "Community",
                                url: "/community",
                              },
                              {
                                label: "Downloads",
                                url: "/downloads",
                              },
                              {
                                label: "Workshops",
                                url: "/workshops",
                              },
                              {
                                label: "Stories",
                                url: "/blog",
                              },
                              {
                                label: "Solutions",
                                url: "/solutions",
                              },
                              {
                                label: "Support",
                                url: "/support",
                              },
                              {
                                label: "Order Form",
                                url: "/legal/documents/order-form",
                              },
                              // {
                              //   label: "Education",
                              //   url: "/education",
                              // },
                            ],
                          },
                        ]}
                        mobile
                      />
                    </Box>
                  </Box>
                ) : undefined}
              </Box>
            ) : (
              <div className="flex items-center w-100">
                <div
                  className="flex items-center pr-10 flex-grow-1 ml-10"
                  style={{ flex: 1 }}
                >
                  <div className="flex items-center space-x-8">
                    <a
                      className={`nav-link ${styles["nav-link"]}`}
                      href="/search"
                      style={{ whiteSpace: "nowrap" }}
                      rel="search"
                    >
                      Find Your Show
                    </a>
                    <DropdownMenu
                      image="/assets/section/menu-products.webp"
                      label="Products"
                      description=" All the tools you'll need to take your production to the next level."
                      chunks={1}
                      options={[
                        {
                          label: "Digital Scenery",
                          options: [
                            {
                              label: "Show Packages",
                              url: "/scenic-projections",
                            },
                            {
                              label: "Backdrops Library",
                              url: "/digital-backdrops",
                            },
                            {
                              label: "View All",
                              url: "/search",
                            },
                          ],
                        },
                        region?.id === 1
                          ? {
                              label: "Stage Gear",
                              options: [
                                {
                                  label: "Projector Rentals",
                                  url: "/stage-gear/projectors",
                                },
                                {
                                  label: "Wireless Microphones",
                                  url: "/stage-gear/wirelesssystems",
                                },
                                {
                                  label: "View All",
                                  url: "/stage-gear",
                                },
                              ],
                            }
                          : {},
                        {
                          label: "Guides",
                          options: [
                            {
                              label: "Choreography Guides",
                              url: "/choreography-guides",
                            },
                          ],
                        },
                      ]}
                    />
                    <DropdownMenu
                      image="/assets/section/menu-company.webp"
                      label="Company"
                      description="Learn more about Broadway Media"
                      options={[
                        {
                          label: undefined,
                          options: [
                            {
                              label: "About Us",
                              url: "/company",
                            },
                            {
                              label: "News",
                              url: "/blog?filter=Press+Release",
                            },
                            {
                              label: "Partners",
                              url: "/partners",
                            },
                            {
                              label: "Clients",
                              url: "/clients",
                            },
                            {
                              label: "Contact Us",
                              url: "/contact",
                            },
                          ],
                        },
                      ]}
                    />

                    <DropdownMenu
                      image="/assets/section/menu-resources.webp"
                      label="Resources"
                      description="Join theatre-makers around the globe celebrating theatre, dance and music"
                      options={[
                        {
                          label: undefined,
                          options: [
                            {
                              label: "Workshops",
                              url: "/workshops",
                            },
                            {
                              label: "Stories",
                              url: "/blog",
                            },
                            {
                              label: "Community",
                              url: "/community",
                            },
                          ],
                        },
                        {
                          label: undefined,
                          options: [
                            {
                              label: "Solutions",
                              url: "/solutions",
                            },
                            {
                              label: "Support",
                              url: "/support",
                            },
                            {
                              label: "Downloads",
                              url: "/downloads",
                            },
                            {
                              label: "Order Form",
                              url: "/legal/documents/order-form",
                            },
                            // {
                            //   label: "Education",
                            //   url: "/education",
                            // },
                          ],
                        },
                      ]}
                    />
                  </div>
                </div>
                {authenticated && !hideProfileActions ? (
                  <div className="flex items-center space-x-4">
                    <Box css="d:inline-flex a:center">
                      {quantity > 0 ? (
                        <>
                          {" "}
                          <DesignedIcon
                            properties={{
                              id: "btn-checkout",
                              cypress: "btn-checkout",
                            }}
                            name="cart/regular"
                            color={transparentFixedNavBar ? "white" : "#e2b576"}
                            size={32}
                            press={handleGoToCheckout}
                          />
                          <div style={{ color: "white" }}>{quantity}</div>
                        </>
                      ) : undefined}
                      <Box css="d:inline-flex a:center j:center" parse="ml:10">
                        <DesignedIcon
                          name="user/regular"
                          color={transparentFixedNavBar ? "white" : "#e2b576"}
                          size={32}
                          press={() => setIsProfileMenuOpen(true)}
                        />
                      </Box>
                    </Box>
                  </div>
                ) : (
                  <div className="flex items-center space-x-4">
                    {hideAuthButtons ? undefined : (
                      <>
                        <a
                          className={`nav-link !w-auto ${
                            transparentFixedNavBar
                              ? "text-white"
                              : "text-brand-primary"
                          } text-label-md`}
                          href="/auth"
                          rel="nofollow"
                        >
                          Sign In
                        </a>
                        <DesignedButton
                          label="Get Started"
                          theme={transparentFixedNavBar ? "white" : "primary"}
                          href={`/contact/start?mode=get-started&referrer=${router.asPath}`}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </nav>
      {authenticated ? (
        <MenuSidebar
          open={isProfileMenuOpen}
          close={() => setIsProfileMenuOpen(false)}
        />
      ) : undefined}
    </>
  );
};

export default Navbar;
