/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThrottlerController } from "@with-nx/hooks-n-helpers";
import { useCallback, useEffect, useState } from "react";

import useService from "../useService";

type CMSHTTPMethods = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";

export interface UseCMSOptions {
  page?: number;
  per?: number;
  ready?: boolean;
  endpoint?: string;
  method?: CMSHTTPMethods;
  throttler?: {
    group: string;
    action: string;
    delay: number;
  };
  search?: string;
  regex?: number;
  filter?: {
    [key: string]: any;
  };
  exists?: {
    [key: string]: any;
  };
  version?: string;
  sort?: {
    [key: string]: any;
  };
  cache?: number;
}

export interface UseCMSResult<T> {
  data?: T[];
  pages: number;
  loading: boolean;
  page: number;
}

const buildParams = ({
  page,
  per,
  filter,
  exists,
  search,
  sort,
  regex,
}: Pick<
  UseCMSOptions,
  "page" | "per" | "filter" | "exists" | "search" | "sort" | "regex"
>) => {
  const params: any = { page, per };

  if (filter) {
    params["filter"] = {};

    Object.entries(filter).map(([key, value]) => {
      params["filter"][key] = Array.isArray(value)
        ? {
            $in: value,
          }
        : value;
    });
  }

  if (exists) {
    if (!params["filter"]) {
      params["filter"] = {};
    }

    Object.entries(exists).map(([key]) => {
      params["filter"][key] = { $exists: true, $not: { $size: 0 } };
    });
  }

  if (search) {
    params["search"] = search;
  }

  if (regex) {
    params["regex"] = regex;
  }

  if (sort) {
    params["sort"] = sort;
  }

  return params;
};

export function useCMS<T>({
  page = 0,
  per = 50,
  ready = true,
  method = "POST",
  endpoint,
  throttler,
  cache,
  search,
  filter,
  exists,
  sort,
  regex,
  version = "/api/v1/",
}: UseCMSOptions): UseCMSResult<T> {
  const defaultServiceOptions = {
    cache: cache || 1_800_000,
    timeout: 10_000,
    retry: 3,
  };

  const makeRequestToCMS = useService("cms", defaultServiceOptions);

  const [data, _data] = useState<T[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    let current = true;
    if (!ready) return;

    const hasThrottlerAndCannotRequest =
      !!throttler &&
      !ThrottlerController.can(
        throttler.action,
        throttler.group,
        throttler.delay
      );

    if (hasThrottlerAndCannotRequest) {
      return;
    }

    (async () => {
      const params = buildParams({
        page,
        per,
        filter,
        exists,
        search,
        sort,
        regex,
      });

      const request: any = await makeRequestToCMS(
        method,
        `${version}${endpoint}`,
        params
      );

      if (current) {
        if (request.data) {
          _pages(request.pages);
          _data(request.data);
        } else if (endpoint?.includes("collection")) {
          _data(request);
        }

        _loading(false);
      }
    })();

    return () => {
      current = false;
    };
  }, [page, per, search, filter, exists, regex, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, ready, search]);

  useEffect(() => {
    if (!filter) return;

    hydrate();
  }, [JSON.stringify({ filter, exists, regex })]);

  return { data, pages, loading, page };
}

export const fetchCMSForSSR = async ({
  page = 0,
  per = 50,
  method = "POST",
  endpoint,
  version = "/api/v1/",
  filter,
  exists,
  search,
  regex,
  sort,
}: UseCMSOptions) => {
  const makeRequestToCMS = useService("cms", {
    revalidate: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });

  const params = buildParams({
    page,
    per,
    filter,
    exists,
    search,
    sort,
    regex,
  });

  const response: any = await makeRequestToCMS(
    method,
    `${version}${endpoint}`,
    {
      page,
      per,
      ...params,
    }
  );

  return response?.data || response;
};
