import { useCMS, UseCMSOptions, UseCMSResult } from "./useCMS";

export type UseCMSAnnouncement = {
  _id: string;
  title?: string;
  badge?: string;
  url?: string;
  color?: string;
  background?: string;
  regionTags?: string[];
};

interface UseCMSAnnouncementsResult<T> extends UseCMSResult<T> {
  announcements?: T[];
  random?: T;
}

const getESTDateFilter = () => {
  const now = new Date();
  const closest10Minute = new Date(Math.floor(now.getTime() / 600000) * 600000);

  const estOffset = -5 * 60 * 60000; // 5 hours in milliseconds
  const estTime = new Date(closest10Minute.getTime() + estOffset);

  // "YYYY-MM-DDTHH:mm"
  return estTime.toISOString().slice(0, 16);
};

export const useCMSAnnouncements = (
  options: UseCMSOptions,
  ready?: boolean
): UseCMSAnnouncementsResult<UseCMSAnnouncement> => {
  const { data, loading, page, pages } = useCMS<UseCMSAnnouncement>({
    ...options,
    throttler: {
      group: "announcements",
      action: "reload",
      delay: 1000,
    },
    filter: {
      expiration: {
        $gte: getESTDateFilter(),
      },
      regionTags: options?.filter?.["regionTags"]?.[0] || "1",
    },
    endpoint: "announcement/filter",
    ready,
  });

  return {
    announcements: data,
    loading,
    page,
    pages,
    random: data ? data[Math.floor(Math.random() * data.length)] : undefined,
  };
};
