/* eslint-disable @typescript-eslint/no-explicit-any */

import { useMobile } from "@with-nx/hooks-n-helpers";
import { Text as TextPrimitive } from "simple-effing-primitive-layout";

import Parser from "./Parser";

class Worker {
  static parse(string: string, defaults?: string) {
    let rule = defaults;
    const items = string.split(" ");
    const others: string[] = [];
    items.map((item) => {
      if (item.includes("!")) {
        rule = item.replace("!", "");
      } else {
        others.push(item);
      }
    });

    const style: {
      [key: string]: unknown;
    } = Parser.parse(others.join(" ")) as {
      [key: string]: unknown;
    };

    if (style["backgroundColor"] !== undefined) {
      style["color"] = style["backgroundColor"];
      style["backgroundColor"] = undefined;
    }

    return {
      rule,
      style,
    };
  }
}

export const Rule = (props: any) => {
  const { rule, style } = Worker.parse(props.parse || "", props.rule || "");
  const mobile = useMobile();

  const shared = {
    family: "Figtree, sans-serif",
    color: "#FFFFFF",
    style: style,
  };

  switch (rule) {
    case "Display Large":
    case "DL":
    case "dl":
      return (
        <TextPrimitive
          size={mobile ? 48 : 96}
          line={mobile ? 64 : 112}
          weight="600"
          tag="h1"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Display Medium":
    case "DM":
    case "dm":
      return (
        <TextPrimitive
          size={mobile ? 36 : 52}
          line={mobile ? 48 : 64}
          weight="600"
          tag="h1"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Display Small":
    case "DS":
    case "ds":
      return (
        <TextPrimitive
          size={mobile ? 28 : 44}
          line={mobile ? 32 : 52}
          weight="600"
          tag="h1"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Display Tiny":
    case "DT":
    case "dt":
      return (
        <TextPrimitive
          size={mobile ? 24 : 36}
          line={mobile ? 32 : 44}
          weight="600"
          tag="h1"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Header Extra Large":
    case "HXL":
    case "hxl":
      return (
        <TextPrimitive
          size={mobile ? 32 : 40}
          line={mobile ? 40 : 50}
          weight="600"
          tag="h2"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Header Large":
    case "HL":
    case "hl":
      return (
        <TextPrimitive
          size={mobile ? 24 : 32}
          line={mobile ? 32 : 40}
          weight="600"
          tag="h2"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Header Medium":
    case "HM":
    case "hm":
      return (
        <TextPrimitive
          size={mobile ? 20 : 28}
          line={mobile ? 28 : 36}
          weight="600"
          tag="h2"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Header Small":
    case "HS":
    case "hs":
      return (
        <TextPrimitive
          size={mobile ? 19 : 24}
          line={mobile ? 24 : 32}
          weight="600"
          tag="h2"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Header Tiny":
    case "HT":
    case "ht":
      return (
        <TextPrimitive
          size={mobile ? 18 : 20}
          line={mobile ? 20 : 28}
          weight="600"
          tag="h2"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Label Extra Large":
    case "LLX":
    case "llx":
      return (
        <TextPrimitive
          size={mobile ? 20 : 22}
          line={mobile ? 24 : 24}
          weight="600"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Label Large":
    case "LL":
    case "ll":
      return (
        <TextPrimitive
          size={mobile ? 16 : 18}
          line={mobile ? 24 : 24}
          weight="600"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Label Large Close":
    case "LLC":
    case "llc":
      return (
        <TextPrimitive
          size={mobile ? 16 : 18}
          line={mobile ? 28 : 32}
          weight="600"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Label Medium":
    case "LM":
    case "lm":
      return (
        <TextPrimitive
          size={mobile ? 15 : 16}
          line={mobile ? 18 : 20}
          weight="600"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Label Small":
    case "LS":
    case "ls":
      return (
        <TextPrimitive
          size={mobile ? 14 : 15}
          line={16}
          weight="600"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Label Tiny":
    case "LT":
    case "lt":
      return (
        <TextPrimitive
          size={mobile ? 12 : 13.5}
          line={16}
          weight="600"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Paragraph Large":
    case "PL":
    case "pl":
      return (
        <TextPrimitive
          size={mobile ? 16 : 18}
          line={mobile ? 18 : 28}
          weight="400"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Paragraph Medium":
    case "PM":
    case "pm":
      return (
        <TextPrimitive
          size={mobile ? 15 : 16}
          line={mobile ? 18 : 24}
          weight="400"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Paragraph Small":
    case "PS":
    case "ps":
      return (
        <TextPrimitive
          size={mobile ? 14 : 15}
          line={mobile ? 18 : 20}
          weight="400"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Paragraph Tiny":
    case "PT":
    case "pt":
      return (
        <TextPrimitive
          size={mobile ? 12 : 13.5}
          line={mobile ? 18 : 20}
          weight="400"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Hero Title":
    case "_T":
    case "_t":
      return (
        <TextPrimitive
          size={mobile ? 24 : 32}
          line={mobile ? 40 : 48}
          weight="600"
          tag="h1"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );

    case "Hero Title Large":
    case "_TL":
    case "_tl":
      return (
        <TextPrimitive
          size={mobile ? 32 : 48}
          line={mobile ? 40 : 52}
          weight="600"
          tag="h1"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    case "Hero Paragraph":
    case "_P":
    case "_p":
      return (
        <TextPrimitive
          size={mobile ? 16 : 18}
          line={mobile ? 18 : 24}
          weight="400"
          tag="h2"
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;

    default:
      return (
        <TextPrimitive
          {...shared}
          {...props}
          native={{
            ...props?.native,
            rel: props?.to
              ? props?.to?.includes("http") &&
                !props?.to?.includes("broadwaymedia")
                ? "nofollow"
                : "noreferrer"
              : undefined,
          }}
        />
      );
      break;
  }
};

export default Rule;
